import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Form, Spinner } from "react-bootstrap"
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { fetchBindedTeachersByClientForManager, fetchClientsBindedToManager } from './http/bindingAPI'
import { fetchGroupsByTeacher, updateJournal, fetchStudentsWithHistory } from './http/teacherAPI'
import ClientSelector from './ClientSelector'
import TeacherSelector from './TeacherSelector'
import GroupTabs from './GroupTabs'
import { MessageAlert } from './widjets/MessageAlert'
import { MonthSelector } from './widjets/MonthSelector'

const ManagerDesktop = observer(() => {
  const { clientStore, configStore, groupStore, teacherStore } = useContext(Context)

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setLoading(true)
    clientStore.setDefaults()
    groupStore.setDefaults()
    teacherStore.setDefaults()
    fetchClientsBindedToManager().then(data => {
      clientStore.setClients(data)
      clientStore.setSelectedClient(null)
    })
    // setShow(false)
    setLoading(false)
  },
    // eslint-disable-next-line
    [])

  const clientSelectHandler = () => {
    setLoading(true)
    fetchBindedTeachersByClientForManager(clientStore.selectedClient.id).then(data => {
      teacherStore.setTeachers(data)
      teacherStore.setSelectedTeacher(null)
    })
    setLoading(false)
  }

  const teacherSelectHandler = () => {
    fetchGroupsByTeacher(teacherStore.selectedTeacher, configStore.month, configStore.year).then(data => {
      groupStore.setStudents([])
      groupStore.setSelectedGroup(0)
      groupStore.setGroups(data.filter(group => (group.client_id === clientStore.selectedClient.id)))
    })
  }


  useEffect(() => {
    groupStore.setGroups([])
    groupStore.setSelectedGroup(null)
    groupStore.setStudents([])
    if (teacherStore.selectedTeacher) {
      teacherSelectHandler()
    }
  },
    // eslint-disable-next-line
    [configStore.month])

  const groupSelectHandler = (groupId) => {
    if (groupStore.changed) {
      configStore.showMessage('Есть не сохраненные изменения', 'danger')
      return
    }
    fetchStudentsWithHistory(groupId, configStore.month, configStore.year).then(data => {
      groupStore.setStudents(data.students, configStore.month, configStore.year)
      groupStore.setLessons(data.lessons)
      groupStore.setSelectedGroup(groupId)
    }
    )
  }

  const saveChangesInJournal = () => {
    let saves = []
    setLoading(true)
    groupStore.students.forEach(student => {
      if (student.journal) {
        updateJournal(student.journal.id, student.journal.lessons, student.journal.credit, student.journal.debit).then(data => {
          saves.push(student.journal.id)
        })
      }

    })
    groupStore.setChanged(false)
    setLoading(false)
    configStore.showMessage('Все изменения сохранены', 'success')
  }

  const resetChangesInJournal = () => {
    groupStore.setChanged(false)
    groupSelectHandler(groupStore.selectedGroup.id)
  }

  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  } else {
    return (
      <Container fluid>

        <MessageAlert />

        <div className='d-flex justify-content-start flex-wrap mt-2'>
          <div className='m-1'>
            <MonthSelector />
          </div>
          <div className='m-1'>
            <ClientSelector selectHandler={clientSelectHandler} />
          </div>
          <div className='m-1'>
            <TeacherSelector selectHandler={teacherSelectHandler} />
          </div>
        </div>

        <div className='mt-2'>
          {teacherStore.selectedTeacher && 
            <div>Телефон для связи: {teacherStore.teachers.find(teacher => teacher.id === teacherStore.selectedTeacher).phone}</div>
          }
        </div>

        <GroupTabs key={'groups'} selectHandler={(keyLink) => groupSelectHandler(keyLink)} />

        {groupStore.selectedGroup &&
          <div key={'group-' + groupStore.selectedGroup.id} className='w-100 overflow-x-auto'>

            <table className='table' style={{width:'auto'}}>
              <thead>
                <tr>
                  <th style={{width:'64px',alignContent:'center',textAlign:'center'}}>#</th>
                  <th style={{width:'196px',alignContent:'center',textAlign:'center'}}>Ученик</th>
                  <th style={{width:'196px',alignContent:'center',textAlign:'center'}}>Посещаемость</th>
                  <th style={{width:'148px',alignContent:'center',textAlign:'center'}}>Задолженность на начало периода, руб.</th>
                  <th style={{width:'148px',alignContent:'center',textAlign:'center'}}>Начислено за период, руб.</th>
                  <th style={{width:'148px',alignContent:'center',textAlign:'center'}}>Внесено по квитанциям за период, руб.</th>
                  <th style={{width:'148px',alignContent:'center',textAlign:'center'}}>Особые отметки</th>
                </tr>
              </thead>
              <tbody>
                {groupStore.students.map((student, index) => {
                  if (student.journal) {
                    return (
                    <tr key={'student'+index} style={(student.balance < 0 || student.journal.credit > student.journal.debit) ? {backgroundColor: '#faebd7'} : {}} >
                      <td style={{width:'64px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}>{index+1}</td>
                      <td style={{width:'196px',alignContent:'center',textAlign:'left',backgroundColor:'transparent'}}>{student.name}</td>
                      <td style={{width:'196px',alignContent:'center',textAlign:'left',backgroundColor:'transparent'}}>Посетил {student.journal.lessons === '' ? '0' : student.journal.lessons.split(';').length} из {groupStore.lessons.length}</td>
                      <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}>{student.balance !== 0 ? student.balance : ''}</td>
                      <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}>
                        <Form.Control size="sm" style={{ width: '128px', maxWidth: "128px" }} value={student.journal.credit} type="number" min={0} max={99999} onChange={e => groupStore.setStudentCredit(student.id, e.target.value)} />
                      </td>
                      <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent', cursor:'copy'}} onClick={() => groupStore.setStudentCredit(student.id, student.journal.debit)}>{student.journal.debit}</td>
                      <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}>{student.journal.info}</td>
                    </tr>
                    )
                  } 
                  
                else {
                    return (
                      <tr key={'student'+index}>
                        <td style={{width:'64px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}>{index+1}</td>
                        <td style={{width:'64px',alignContent:'center',textAlign:'left',backgroundColor:'transparent'}}>{student.name}</td>
                        <td style={{width:'196px',alignContent:'center',textAlign:'left',backgroundColor:'transparent'}}>нет записи</td>
                        <td style={{width:'196px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}></td>
                        <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}></td>
                        <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}></td>
                        <td style={{width:'148px',alignContent:'center',textAlign:'center',backgroundColor:'transparent'}}></td>
                      </tr>
                    )
                  }
                  })
                }
              </tbody>
            </table>
          </div>
        }
        {groupStore.changed &&
          <div className='d-flex justify-content-start flex-wrap mt-1'>
            <Button className='mt-2' style={{ width: "auto" }} variant="outline-success" onClick={() => saveChangesInJournal()}>Сохранить журнал</Button>
            <Button className={window.innerWidth > 480 ? 'ms-2 mt-2' : 'mt-2'} style={{ width: "auto" }} variant="outline-danger" onClick={() => resetChangesInJournal()}>Сбросить последние изменения</Button>
          </div>
        }

      </Container>
    )
  }

}
)
export default ManagerDesktop