import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { Button, Container, Form, InputGroup, Pagination, Spinner } from "react-bootstrap"
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { fetchBindedClientsByTeacher, fetchBindedStreamsByTeacher } from './http/bindingAPI'
import { fetchGroupsByTeacher, createGroup, updateGroup, createJournal, createJournals, removeJournal, updateJournal, createStudent, updateStudent, fetchStudentsWithJournals, fetchLessons, createLesson, updateLesson, deleteLesson, fetchSubjects, fetchTopics } from './http/teacherAPI'
import ClientSelector from './ClientSelector'
import CourseStreamSelector from './CourseStreamSelector'
import { CalendarCheck, CalendarX, PencilSquare, Save, Cash, DashCircle, Eject } from 'react-bootstrap-icons';
import GroupTabs from './GroupTabs'
import Modal from 'react-bootstrap/Modal';
import { MessageAlert } from './widjets/MessageAlert'
import { MonthSelector } from './widjets/MonthSelector'
import SubjectSelector from './widjets/SubjectSelector'

const TeacherDesktop = observer(() => {
  const { clientStore, configStore, courseStore, streamStore, groupStore, userStore } = useContext(Context)

  const [loading, setLoading] = useState(true)
  let [newGroupName, setNewGroupName] = useState('')
  let [newStudentName, setNewStudentName] = useState('')
  let [show, setShow] = useState(false);
  let [showLessonEditor, setShowLessonEditor] = useState(false);
  let [showDelStudentDialog, setShowDelStudentDialog] = useState(false);
  let [newGroupId, setNewGroupId] = useState(0);
  let [showGroupSelector, setShowGroupSelector] = useState(false);
  let [editedLessonId, setEditedLessonId] = useState(0);
  let [editedLessonTheme, setEditedLessonTheme] = useState('');
  let [editedLessonDay, setEditedLessonDay] = useState(1);
  let [editedStudentId, setEditedStudentId] = useState(0);
  let [editedStudentName, setEditedStudentName] = useState('');
  let [date, setDate] = useState('2024-10-09');
  let [journal, setJournal] = useState(null);
  let textAreaRef = useRef(null)

  useEffect(() => {
    clientStore.setDefaults()
    courseStore.setDefaults()
    streamStore.setDefaults()
    groupStore.setDefaults()
    fetchBindedClientsByTeacher(userStore.user.id).then(data => {
      clientStore.setClients(data)
      if (clientStore.clients.length > 0) {
        clientStore.setSelectedClient(clientStore.clients[0])
        clientSelectHandler()
      }
    })
    setLoading(false)
  },
    // eslint-disable-next-line
    [])

  useEffect(() => {
    groupStore.setGroups([])
    groupStore.setSelectedGroup(null)
    groupStore.setStudents([])
    groupStore.setLessons([])
    if (clientStore.selectedClient && streamStore.selectedStream) {
      csSelectHandler()
    }
  },
    // eslint-disable-next-line
    [configStore.month])

  const clientSelectHandler = () => {
    setLoading(true)
    fetchBindedStreamsByTeacher(userStore.user.id, clientStore.selectedClient.id).then(data => {
      courseStore.setCourses(data.courses)
      courseStore.setSelectedCourse(null)
      streamStore.setStreams(data.streams, [])
      streamStore.setSelectedStream(null)
    })
    setLoading(false)
  }

  const csSelectHandler = () => {
    if (groupStore.changed) {
      configStore.showMessage('Есть не сохраненные изменения', 'danger')
      return
    }
    setLoading(true)
    if (clientStore.selectedClient && streamStore.selectedStream) {
      fetchGroupsByTeacher(userStore.user.id, configStore.month, configStore.year).then(data => {
        groupStore.setGroups(data.filter(group => (group.client_id === clientStore.selectedClient.id && group.course_id === courseStore.selectedCourse.id && group.stream_id === streamStore.selectedStream.id)))
      })
      fetchSubjects(courseStore.selectedCourse.id).then(data => {
        groupStore.setSubjects(data)
      })
    }
    groupStore.setStudents([])
    groupStore.setLessons([])
    groupStore.setSelectedGroup(0)
    setLoading(false)
  }

  const groupSelectHandler = (groupId) => {
    if (groupStore.changed) {
      configStore.showMessage('Есть не сохраненные изменения', 'danger')
      return
    }
    fetchStudentsWithJournals(groupId, configStore.month, configStore.year).then(data => {
      groupStore.setStudents(data)
      groupStore.setSelectedGroup(groupId)
    })
    fetchLessons(configStore.month, configStore.year, groupId).then(data => {
      groupStore.setLessons(data)
    })
  }
  
  const subjectSelectHandler = () => {
    fetchTopics(courseStore.selectedCourse.id, groupStore.selectedSubject.id).then(data =>{
      groupStore.setTopics(data)
    })
  }
  

  const createGroupHandler = () => {
    if (newGroupName.length < 4) {
      configStore.showMessage('В названии группы должно быть минимум 4 символа', 'warning')
      return
    }
    createGroup(newGroupName, clientStore.selectedClient.id, courseStore.selectedCourse.id, streamStore.selectedStream.id, userStore.user.id, configStore.month, configStore.year).then(data => {
      configStore.showMessage(data.message, data.status)
      setNewGroupName('')
      csSelectHandler()
    })
  }

  const updateGroupName = (groupId, newName) => {
    if (newName.length > 4) {
      groupStore.setChanged(true)
    }
    groupStore.setGroupName(groupId, newName)
  }

  const createStudentHandler = () => {
    if (newStudentName.length < 4) {
      configStore.showMessage('Имя ученика должно быть длиной минимум в 4 символа', 'warning')
      return
    }
    createStudent(newStudentName, groupStore.selectedGroup.id, configStore.month, configStore.year).then(data => {
      groupSelectHandler(groupStore.selectedGroup.id)
      configStore.showMessage(data.message, data.status)
      setNewStudentName('')
    })
  }

  const updateStudentHandler = (studentId) => {
    let newStudentName = groupStore.students.find(student => student.id === studentId).name
    if (newStudentName.length < 4 && newStudentName.length !== 0) {
      configStore.showMessage('Имя ученика должно быть длиной минимум в 4 символа', 'warning')
      return
    }
    updateStudent(studentId, newStudentName.length === 0 ? '-' : newStudentName, 0, newStudentName.length === 0 ? 0 : 1, configStore.month, configStore.year).then(data => {
      groupStore.setStudentChanged(studentId, false)
      configStore.showMessage(data.message, data.status)
      if (newStudentName.length === 0) {
        groupSelectHandler(groupStore.selectedGroup.id)
        setShowDelStudentDialog(false)
      }
    })
  }

  const createStudentJournalHandler = (studentId) => {
    createJournal(studentId, groupStore.selectedGroup.id, clientStore.selectedClient.id, configStore.month, configStore.year).then(data => {
      groupSelectHandler(groupStore.selectedGroup.id)
      configStore.showMessage(data.message, data.status)
    }
    )
  }

  const createStudentJournals = async () => {
    if (groupStore.lessons.length < 1) {
      configStore.showMessage('Создайте хотя бы одно занятие', 'warning')
    }
    let studentIds = []
    groupStore.students.forEach((student) => {
      if (student.journals.length === 0) {
        studentIds.push(student.id)
      }
    })
    if (studentIds.length > 0) {
      createJournals(studentIds.join(';'), groupStore.selectedGroup.id, clientStore.selectedClient.id, configStore.month, configStore.year).then(data => {
        groupSelectHandler(groupStore.selectedGroup.id)
        configStore.showMessage(data.message, data.status)
      })
    }
    
  }

  const removeStudentJournalHandler = () => {
    if (journal) {
      removeJournal(journal, configStore.month, configStore.year).then(data => {
        groupSelectHandler(groupStore.selectedGroup.id)
        configStore.showMessage(data.message, data.status)
      })
    }
    setShow(false)
    setJournal(null)
  }

  const saveChangesInJournal = () => {
    let saves = []
    setLoading(true)
    groupStore.students.forEach(student => {
      if (student.journal) {
        updateJournal(student.journal.id, student.journal.lessons, student.journal.credit, student.journal.debit, student.journal.info, configStore.month, configStore.year).then(data => {
          saves.push(student.journal.id)
        })
      }
    })
    groupStore.groups.forEach(group => {
      updateGroup(group.id, group.name)
    })
    groupStore.setChanged(false)
    setLoading(false)
    configStore.showMessage('Все изменения сохранены', 'success')
  }

  const resetChangesInJournal = () => {
    groupStore.setChanged(false)
    groupSelectHandler(groupStore.selectedGroup.id)
  }

  const openLessonEditor = (lessonId) => {
    if (lessonId > 0) {
      let lesson = groupStore.lessons.find(lesson => lesson.id === +lessonId)
      setDate(`${configStore.year}-${configStore.month < 10 ? '0'+configStore.month : configStore.month}-${lesson.day < 10 ? '0'+lesson.day :lesson.day}`)
      setEditedLessonTheme(lesson.theme)
      setEditedLessonDay(lesson.day)
    } else {
      setDate(`${configStore.year}-${configStore.month < 10 ? '0'+configStore.month : configStore.month}-${new Date().getDate() < 10 ? '0'+new Date().getDate() : new Date().getDate()}`)
      setEditedLessonTheme('')
      setEditedLessonDay(new Date().getDate())
    }
    setShowLessonEditor(true)
    setEditedLessonId(lessonId)
  }

  const createOrUpdateLessonHandler = () => {
    if (groupStore.changed) {
      configStore.showMessage('Сначала сохраните внесенные изменения в журнале', 'danger')
      return
    }
    if (editedLessonTheme.length < 4) {
      configStore.showMessage('Тема занятия должна содержать не менее 4 символов', 'danger')
      return
    }
    if (editedLessonId > 0) {
      updateLesson(editedLessonId, editedLessonDay, editedLessonTheme, configStore.month, configStore.year).then(data => {
        configStore.showMessage(data.message, data.status)
        setShowLessonEditor(false)
        let lessons = [...groupStore.lessons]
        let lesson = lessons.find(lesson => lesson.id === editedLessonId)
        lesson.theme = editedLessonTheme
        lesson.day = editedLessonDay
        groupStore.setLessons(lessons)
      })
    } else {
      createLesson(editedLessonDay, configStore.month, configStore.year, editedLessonTheme, groupStore.selectedGroup.id, configStore.month, configStore.year).then(data => {
        setShowLessonEditor(false)
        groupSelectHandler(groupStore.selectedGroup.id)
        configStore.showMessage(data.message, data.status)
      })
    }
    
  }

  const deleteLessonHandler = () => {
    let hasLesson = false
    groupStore.students.forEach(student => {
      if (student.journal) {
        if (student.journal.lessons.length > 0) {
          hasLesson = student.journal.lessons.split(';').find(lessonId => +lessonId === editedLessonId)
        }
        
      }
    })
    if (hasLesson) {
      configStore.showMessage('Удалять нельзя! Снимите все отметки о посещении этого занятия и сохраните журнал.', 'danger')
    } else {
      deleteLesson(editedLessonId, configStore.month, configStore.year).then(data => {
        setShowLessonEditor(false)
        groupSelectHandler(groupStore.selectedGroup.id)
        configStore.showMessage(data.message, data.status)
      })
      
    }
  }
  
  const openStudentGroupSelector = (studentId, studentName) => {
    setEditedStudentId(studentId)
    setEditedStudentName(studentName)
    setShowGroupSelector(true)
  }

  const setStudentGroup = () => {
    updateStudent(editedStudentId, editedStudentName, newGroupId, 1, configStore.month, configStore.year).then(data => {
      configStore.showMessage(data.message, data.status)
      setShowGroupSelector(false)
      groupSelectHandler(groupStore.selectedGroup.id)
    })
  }
  

  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  } else {
    return (
      <Container fluid>

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Внимание!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Подтвердите удаление записи журнала</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Нет, не надо!
            </Button>
            <Button variant="primary" onClick={() => removeStudentJournalHandler()}>
              Удалить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDelStudentDialog} onHide={() => setShowDelStudentDialog(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Внимание!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Подтвердите удаление ученика</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDelStudentDialog(false)}>
              Нет, не надо!
            </Button>
            <Button variant="primary" onClick={() => updateStudentHandler(editedStudentId)}>
              Удалить
            </Button>
          </Modal.Footer>
        </Modal>
        
        <Modal show={showGroupSelector} onHide={() => setShowGroupSelector(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Перевод в группу</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <div>Ученика: {editedStudentName}</div>
                <div className='d-flex flex-column mt-2 overflow-y-scroll' style={{height: '320px'}}>
                  {groupStore.groups.map(group => (
                    <div key={group.id} className={'p-2 '+ ((+group.id === +newGroupId) ? 'bg-success' : '')} role="button" onClick={() => {setNewGroupId(group.id)}}>{group.name}</div>
                  ))}
                </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Button variant="primary" onClick={() => setStudentGroup()}>
              Перевести
            </Button>
            <Button variant="primary" onClick={() => setShowGroupSelector(false)}>
              Отмена
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showLessonEditor} onHide={() => setShowLessonEditor(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Редактор занятия</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {courseStore.selectedCourse &&
              <Fragment>
                <div className='mt-2'>
                  <InputGroup>
                    <InputGroup.Text> Дата занятия: </InputGroup.Text>
                    <Form.Control size="sm" type="date" value={date} onChange={e => {
                      setDate(e.target.value)
                      setEditedLessonDay(+e.target.value.split('-')[2])
                    }} />
                  </InputGroup>
                </div>
                <div className='d-flex flex-column mt-2 '>
                  <div>Тема занятия: (можно выбрать из справочника)</div>
                  <Form.Control ref={textAreaRef} size="sm" style={{ width: '100%'}} value={editedLessonTheme} onChange={e => {
                    setEditedLessonTheme(e.target.value)
                    e.target.style.height = `${e.target.scrollHeight}px`
                  }} type="text" maxLength={512} as="textarea"/>
                </div>
                <div className='mt-2'>
                  <div>Выберите предмет чтобы увидеть список тем: </div>
                  <SubjectSelector selectHandler={subjectSelectHandler} />
                </div>
                {groupStore.selectedSubject &&  
                  <Fragment>
                    <div>Список тем: </div>
                    <hr></hr>
                  </Fragment>
                }
                <div className='d-flex flex-column mt-2 overflow-y-scroll' style={{height: '480px'}}>
                  {groupStore.topics.map(topic => (
                    <div key={topic.id} className='p-2 accent-lighting' role="button" onClick={() => {
                      setEditedLessonTheme(editedLessonTheme+'\r\n'+groupStore.selectedSubject.name+'\r\n'+topic.name)
                      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
                    }}>{topic.name}</div>
                  ))}
                </div>
              </Fragment>  
            }
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Button variant="primary" onClick={() => createOrUpdateLessonHandler()}>
              Сохранить
            </Button>
            {editedLessonId > 0 &&
            <Button variant="primary" onClick={() => deleteLessonHandler()}>
              Удалить
            </Button>
            }
            
            
          </Modal.Footer>
        </Modal>

        <MessageAlert />
        <div className='mt-2'>
          <MonthSelector />
        </div>

        <div className='d-flex justify-content-start flex-wrap mt-2'>
          <div className='m-1'>
            <ClientSelector selectHandler={clientSelectHandler} />
          </div>
          <div className='m-1'>
            <CourseStreamSelector selectHandler={csSelectHandler} />
          </div>
        </div>

        {streamStore.selectedStream &&
          <div className='d-flex justify-content-start flex-wrap mt-2'>
            <Form.Control size="sm" style={{ width: "264px", maxWidth: "100%" }} value={newGroupName} onChange={e => setNewGroupName(e.target.value)} type="text" placeholder='Введите название для новой группы' maxLength={64} />
            <Button className='ms-2' style={{ width: "auto" }} variant="outline-success" onClick={() => createGroupHandler()}>Добавить</Button>
          </div>
        }

        <GroupTabs key={'groups'} selectHandler={(keyLink) => groupSelectHandler(keyLink)} />

        {groupStore.selectedGroup &&
          <div>
            <InputGroup className="mt-2 mb-4">
              <InputGroup.Text role='button'> <PencilSquare /> </InputGroup.Text>
              <Form.Control size="sm" style={{ maxWidth: "128px" }} onChange={e => updateGroupName(groupStore.selectedGroup.id, e.target.value)} key={'edit-' + groupStore.selectedGroup.id} value={groupStore.selectedGroup.name} type="text" />
            </InputGroup>


            <div className='mt-4 mb-4 p-2'>
              <h3>Расписание занятий</h3>
              {groupStore.selectedGroup &&
                <div className='d-flex justify-content-start flex-wrap mt-2'>
                  <Button className='mt-2' style={{ width: "auto" }} variant="outline-success" onClick={() => openLessonEditor(0)}>Добавить занятие</Button>
                </div>
              }
              {groupStore.lessons.map((lesson, index) => (
                <div key={lesson.id} className='d-flex flex-column mt-2'>
                  <div style={{ padding: '7px 0px' }}>
                    <span style={{ fontWeight: '700' }}> ЗАНЯТИЕ № {index + 1} </span>
                    <span style={{ padding: '3px 7px', border: '1px solid #cccccc', borderRadius: '7px', marginLeft: '13px', backgroundColor:'#cccccc22'}}>
                      {lesson.day} {configStore.monthNameRP} {configStore.year} г.
                    </span>
                  </div>
                  <div style={{ padding: '3px 7px', border: '1px solid #cccccc22', borderRadius: '7px', marginTop: '7px', cursor: 'pointer'}} onClick={() => openLessonEditor(lesson.id)}>{lesson.theme.split('\n').map((el,i) => (<p key= {i+'-'+lesson.id} style={{padding: '0', margin: '0', fontSize:'80%'}}>{el}</p>)) }</div>
                </div>
              ))}
              
            </div>

            <div className='mt-4 p-2'>
              <h3>Журнал посещений и учет оплат по квитанциям</h3>
              {groupStore.students.length > 0 &&
              <Button className='mt-2 mb-2' style={{ width: "auto" }} variant="outline-success" onClick={() => createStudentJournals()}>Открыть журналы</Button>
              }
              {groupStore.students.map((student, index) => (
                <div key={student.id} className='d-flex justify-content-start flex-wrap align-items-center' style={(index % 2 === 0) ? { backgroundColor: '#fff' } : { backgroundColor: '#cccccc22' }}>
                  <div className='m-1'  key={student.id + 'data'}>
                    <InputGroup>
                      {student.changed &&
                        <InputGroup.Text role='button' onClick={() => openStudentGroupSelector(student.id, student.name)}> <Eject/> </InputGroup.Text>
                      }
                      <Form.Control size="sm" style={{ maxWidth: "100%" }} value={student.name} type="text" 
                        onFocus= {
                          () => {
                            groupStore.setStudentChanged(student.id, true)
                          }
                        }
                        onChange = {
                          e => groupStore.setStudentName(student.id, e.target.value)
                        } 
                      />
                      {student.changed &&
                        <InputGroup.Text role='button' onClick = {
                          () => {
                            if (student.name === '') {
                              setEditedStudentId(student.id)
                              setShowDelStudentDialog(true)
                            } else {
                              updateStudentHandler(student.id)
                            }
                            
                          } 
                        }> {student.name === '' ? <DashCircle/> : <Save />} </InputGroup.Text>
                      }
                      {student.journals.length === 0 &&
                        <InputGroup.Text role='button' onClick={() => createStudentJournalHandler(student.id)}> <CalendarCheck /> </InputGroup.Text>
                      }
                      {student.journals.length > 0 &&
                        <InputGroup.Text role='button' onClick={() => {
                          setJournal(student.journal.id)
                          setShow(true)
                        }}>
                          <CalendarX />
                        </InputGroup.Text>
                      }
                    </InputGroup>
                  </div>
                  {student.journals.length > 0 &&
                    <div className='m-1' key={student.id + 'journal'}>
                      <Pagination size="sm" className='m-0 d-flex flex-wrap'>
                        {groupStore.lessons.map((lesson) => (
                          <Pagination.Item key={`${student.id}+${lesson.id}`} active={student.journal.lessons.split(';').find(lessonId => +lessonId === +lesson.id)} style={{ cursor: 'pointer', minWidth: '32px', textAlign: 'center' }} onClick={() => groupStore.setStudentJournal(student.id, lesson.id)}>
                            {lesson.day}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    </div>
                  }
                  {student.journals.length > 0 &&
                    <div className='m-1' key={student.id + 'payments'}>
                      <InputGroup>
                        <InputGroup.Text role="button" onClick={() => groupStore.setStudentDebit(student.id, student.journal.credit)}> <Cash /> </InputGroup.Text>
                        <Form.Control size="sm" style={{ maxWidth: "64px" }} value={student.journal.debit} type="text" onChange={e => groupStore.setStudentDebit(student.id, e.target.value)} />
                        <InputGroup.Text> i: </InputGroup.Text>
                        <Form.Control size="sm" style={{ maxWidth: "264px" }} value={student.journal.info} type="text" onChange={e => groupStore.setStudentInfo(student.id, e.target.value)} />
                      </InputGroup>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        }
        
        {groupStore.changed &&
          <div className='d-flex justify-content-start flex-wrap mt-1'>
            <Button className='mt-2' style={{ width: "auto" }} variant="outline-success" onClick={() => saveChangesInJournal()}>Сохранить журнал</Button>
            <Button className={window.innerWidth > 480 ? 'ms-2 mt-2' : 'mt-2'} style={{ width: "auto" }} variant="outline-danger" onClick={() => resetChangesInJournal()}>Сбросить последние изменения</Button>
          </div>
        }
        {groupStore.selectedGroup &&
          <div className='d-flex justify-content-start flex-wrap mt-2'>
            <Form.Control size="sm" style={{ width: "192px", maxWidth: "100%" }} value={newStudentName} onChange={e => setNewStudentName(e.target.value)} type="text" placeholder='Введите ФИО уч-ся' maxLength={64} />
            <Button className='ms-2' style={{ width: "auto" }} variant="outline-success" onClick={() => createStudentHandler()}>Добавить</Button>
          </div>
        }
        
        <div style={{ padding: '30px 0px' }}></div>
      </Container>
    )
  }

}
)
export default TeacherDesktop