import React, {useContext} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {authRoutes, publicRoutes} from '../routes'
import {Context} from '../index'
import {observer} from 'mobx-react-lite'
import Auth from './Auth'


const AppRouter = observer(() => {
  const {userStore} = useContext(Context)
  return (
    <div>
    {!userStore.isAuth && <Auth />}
    <Routes>
        {
            userStore.isAuth && authRoutes.map(({path, Component}) => 
                <Route key={path} path={path} element={<Component />} exact/>
            )
        },
        {
            publicRoutes.map(({path, Component}) => 
            <Route key={path} path={path} element={<Component />} exact/>
            )
        }
        <Route path="/*" element={<Navigate replace to="/"/>} />
    </Routes>
  </div>
  )
})

export default AppRouter