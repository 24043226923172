import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { Button, Container, Form, InputGroup, Spinner } from "react-bootstrap"
import { Save, Calculator } from 'react-bootstrap-icons';
import { nameAppearenсe } from '../consts'
import { fetchPayroll, createOrUpdateTeacherWage, createOrUpdateScope } from './http/scopeAPI'
import { fetchClientsBindedToManager } from './http/bindingAPI'
import { MonthSelector } from './widjets/MonthSelector'
import { MessageAlert } from './widjets/MessageAlert'
import ClientSelector from './ClientSelector'

const PayrollPage = observer(() => {

  const { userStore, clientStore, configStore } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [payroll, setPayroll] = useState([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    setLoading(true)
    clientStore.setDefaults()
    fetchClientsBindedToManager().then(data => {
      clientStore.setClients(data)
      clientStore.setSelectedClient(null)
    })
    setShow(false)
    setLoading(false)
  },
    // eslint-disable-next-line
    [])

    useEffect(() => {
      setPayroll([])
      if (clientStore.selectedClient) {
        getPayrollData()
      } 
    },
      // eslint-disable-next-line
      [configStore.month])

  const getPayrollData = () => {
    setLoading(true)
    fetchPayroll(configStore.year, configStore.month, userStore.user.id, clientStore.selectedClient.id).then(data => {
      setPayroll(data)
      setLoading(false)
      configStore.showMessage('Ведомость сформирована', 'success')
    })
  }

  const clientSelectHandler = () => {
    setPayroll([])
    getPayrollData()
  }

  const setTeacherCourseAmount = (teacherId, courseId, amount) => {
    let tmpData = [...payroll]
    let foundedTeacher = null
    let foundedCourse = null
    tmpData.forEach(client => {
      foundedTeacher = client.teachers.find(teacher => +teacher.id === +teacherId)
      if (foundedTeacher) {
        foundedCourse = foundedTeacher.courses.find(course => course.course_id === +courseId)
        if (foundedCourse) {
          foundedCourse.course_amount = +amount
        }
      }
    })
    setPayroll(tmpData)
  }

  const saveWage = (teacherId, courseId, amount) => {
    if (+amount !== 0) {
      createOrUpdateTeacherWage(configStore.year, configStore.month, teacherId, courseId, amount).then(data => {
        configStore.showMessage('Зарплата начислена', 'success')
      })
    } else {
      createOrUpdateTeacherWage(configStore.year, configStore.month, teacherId, courseId, 0).then(data => {
        configStore.showMessage('Начисление з/п отменено', 'success')
      })
    }
  }

  const saveDeductions = (clientId) => {
    let nowClient = payroll.find(client => client.id === +clientId)
    createOrUpdateScope(configStore.year, configStore.month, userStore.user.id, clientId, +nowClient.chief, +nowClient.assist, +nowClient.extra, nowClient.comment).then(data => {
      configStore.showMessage('Информация о представительских расходах сохранена', 'success')
    })
  }

  const setChiefAmount = (clientId, amount) => {
    let tmpData = [...payroll]
    let nowClient = tmpData.find(client => client.id === +clientId)
    if (+amount >= 0) {
      nowClient.chief = +amount
    } else {
      let selectedCoursesSum = 0
      nowClient.courses.forEach(course => {
        selectedCoursesSum += course.status ? course.sum : 0
      });
      nowClient.chief = 0.1 * selectedCoursesSum
    }
    setPayroll(tmpData)
  }

  const setAssistAmount = (clientId, amount) => {
    let tmpData = [...payroll]
    let nowClient = tmpData.find(client => client.id === +clientId)
    if (+amount >= 0) {
      nowClient.assist = +amount
    } else {
      let selectedCoursesSum = 0
      nowClient.courses.forEach(course => {
        selectedCoursesSum += course.status ? course.sum : 0
      });
      nowClient.assist = 0.05 * selectedCoursesSum
    }
    setPayroll(tmpData)
  }

  const setExtraAmount = (clientId, amount) => {
    let tmpData = [...payroll]
    tmpData.find(client => client.id === +clientId).extra = +amount
    setPayroll(tmpData)
  }
  const setComment = (clientId, comment) => {
    let tmpData = [...payroll]
    tmpData.find(client => client.id === +clientId).comment = comment
    setPayroll(tmpData)
  }

  const setCourseStatus = (clientId, courseId, status) => {
    let tmpData = [...payroll]
    let targetClient = tmpData.find(client => +client.id === +clientId)
    targetClient.courses.find(course => +course.id === +courseId).status = status
    setPayroll(tmpData)
  }

  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  } else {
    return (
      <Container fluid>

        <MessageAlert />

        <div className='print-hidden mt-2'>
          <MonthSelector />
        </div>
        <div className='print-hidden mt-2'>
          <ClientSelector selectHandler={clientSelectHandler} />
        </div>

        <div className='print-hidden mt-3' style={{display: 'flex'}}>
          <Button style={{ width: "auto" }} variant="outline-success" onClick={() => getPayrollData()}>Обновить</Button>
        </div>

        {payroll.length > 0 &&
        <div className='d-flex flex-column mt-2'>
          <h3 className='mt-4'>Ведомость за {configStore.monthName} {configStore.year}</h3>
          <hr></hr>
          <div className='mt-2 w-100 overflow-x-auto'>
            <table className="table table-bordered" style={{width: 'auto'}}>
              <thead>
                <tr>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '32px'}}>#</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '196px'}}>ФИО</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}} className='print-hidden'>Учеников</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}} className='print-hidden'>Собрано, руб.</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}} className='print-hidden'>Расчетное значение ЗП, руб.</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '164px'}}> Выдано, руб.</th>
                  <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '164px'}}>Подпись</th>
                </tr>
              </thead>
              <tbody>
                {payroll[0].teachers.map((teacher, index) => (
                  <Fragment key={'fragment-' + teacher.id}>
                    <tr key={'teacher-' + teacher.id}>
                      <td style={{alignContent: 'center', textAlign: 'center', backgroundColor: '#cccccc22'}}>{index+1}</td>
                      <td style={{alignContent: 'center', textAlign: 'left', backgroundColor: '#cccccc22'}}>{nameAppearenсe(teacher.name)}</td>
                      <td style={{alignContent: 'center', textAlign: 'right', backgroundColor: '#cccccc22'}} className='print-hidden'>{teacher.count}</td>
                      <td style={{alignContent: 'center', textAlign: 'right', backgroundColor: '#cccccc22'}} className='print-hidden'>{new Intl.NumberFormat('ru-RU').format(teacher.sum)}</td>
                      <td style={{alignContent: 'center', textAlign: 'right', backgroundColor: '#cccccc22'}} className='print-hidden'>{new Intl.NumberFormat('ru-RU').format(teacher.calc_amount)}</td>
                      <td style={{alignContent: 'center', textAlign: 'right', backgroundColor: '#cccccc22'}}>
                        <div>{new Intl.NumberFormat('ru-RU').format(teacher.amount)}</div>
                      </td>
                      <td></td>
                    </tr>
                    {teacher.courses.map(course => (
                      <tr key={'teacher-course-' + teacher.id + '-' + course.course_id} style={{fontSize: '80%'}} className='print-hidden'>
                        <td></td>
                        <td style={{alignContent: 'center', textAlign: 'right'}}>{course.course_name}</td>
                        <td style={{alignContent: 'center', textAlign: 'right'}}>{course.course_count}</td>
                        <td style={{alignContent: 'center', textAlign: 'right'}}>{new Intl.NumberFormat('ru-RU').format(course.course_sum)}</td>
                        <td style={{alignContent: 'center', textAlign: 'right', cursor: 'copy'}} onClick={() => setTeacherCourseAmount(teacher.id, course.course_id, course.course_calc_amount)}>{new Intl.NumberFormat('ru-RU').format(course.course_calc_amount)}</td>
                        <td style={{alignContent: 'center', backgroundColor: '#cccccc22'}}>
                          <div className='print-hidden' style={{display: 'flex'}}>
                            <InputGroup style={{ width: "auto" }}>
                              <Form.Control size="sm" style={{ minWidth: "96px" }} value={course.course_amount} onChange={e => setTeacherCourseAmount(teacher.id, course.course_id, e.target.value)} type="number" min={0} />
                              <InputGroup.Text role='button' onClick={() => saveWage(teacher.id, course.course_id, course.course_amount)}> <Save /> </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className='only-on-print'>{teacher.amount}</div>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className='mt-2 mb-4 p-2'>
            <Button style={{ width: "128px" }} variant="outline-info" onClick={() => setShow(!show)}> {show ? 'Свернуть':'Развернуть'} </Button> 
          </div>
          
          <div className='print-hidden' style={show ? {display: 'flex', flexDirection: 'column'} : {display: 'none'}}>
            <h3 className='mt-4'>Статистика по направлениям:</h3>
            <div className='d-flex justify-content-start align-content-center flex-wrap'>
              {payroll[0].courses.map(course => {
                if (course.count > 0) {
                  return (
                    <div className='d-flex flex-column border-end mt-1' style={{ minWidth: '128px' }} key={course.id + '-d'}>
                      <div className='p-1 text-center border-bottom'> {course.name} </div>
                      <div className='p-1 text-center'> {course.count} ({course.sum}) </div>
                      <div className='p-2'>
                        <Form.Check className='d-flex justify-content-center' type={'checkbox'} checked={course.status} value={course.status} onChange={e => setCourseStatus(payroll[0].id, course.id, e.target.checked)} label='&nbsp;- з/п' />
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={course.id+'-d'}></div>
                  )
                }
                })
              }
            </div>
           
            <h3 className='mt-4'>Представительские расходы:</h3>

            <div className='d-flex mt-2'>
              <InputGroup key={payroll[0].id + '-chief'} style={{ width: "auto" }}>
                <InputGroup.Text role='button'> Руководитель: </InputGroup.Text>
                <Form.Control size="sm" style={{ minWidth: "96px", maxWidth: "128px" }} value={payroll[0].chief} onChange={e => setChiefAmount(payroll[0].id, e.target.value)} type="number"  />
              </InputGroup>
              <div className='ms-2 d-flex align-items-center cursor-pointer' role="button" onClick={() => setChiefAmount(payroll[0].id, -1)}> <Calculator /> </div>
            </div>

            <div className='d-flex mt-2'>
              <InputGroup key={payroll[0].id + '-assist'} style={{ width: "auto" }}>
                <InputGroup.Text role='button'> Завучи: </InputGroup.Text>
                <Form.Control size="sm" style={{ minWidth: "96px", maxWidth: "128px" }} value={payroll[0].assist} onChange={e => setAssistAmount(payroll[0].id, e.target.value)} type="number" min={0} />
              </InputGroup>
              <div className='ms-2 d-flex align-items-center cursor-pointer' role="button" onClick={() => setAssistAmount(payroll[0].id, -1)}> <Calculator /> </div>
            </div>

            <div className='d-flex mt-2'>
              <InputGroup key={payroll[0].id + '-extra'} style={{ width: "auto" }}>
                <InputGroup.Text role='button'> Экстра: </InputGroup.Text>
                <Form.Control size="sm" style={{ minWidth: "96px", maxWidth: "128px" }} value={payroll[0].extra} onChange={e => setExtraAmount(payroll[0].id, e.target.value)} type="number" min={0} />
              </InputGroup>
            </div>

            <div className='d-flex mt-2'>
              <InputGroup key={payroll[0].id + '-comment'} style={{ width: "auto" }}>
                <InputGroup.Text role='button'> Комментарий: </InputGroup.Text>
                <Form.Control size="sm" style={{ minWidth: "128px" }} value={payroll[0].comment} onChange={e => setComment(payroll[0].id, e.target.value)} type="text" maxLength={36} placeholder='не более 32 символов' />
              </InputGroup>
            </div>

            <div className='mt-2 mb-4 p-2'>
              <Button style={{ width: "128px" }} variant="outline-info" onClick={() => saveDeductions(payroll[0].id)}> <Save /> Сохранить </Button> 
            </div>
          </div>
        </div>
        }

       

      </Container>
    )
  }
})

export default PayrollPage